.dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
 
}


.topConteiner {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.bottomConteiner {
    width: 95%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.messageTopBlock, .mapTopBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-bottom: 1px solid #E7EAEF;
  
}

.message {

    width: 30%;
    height: 90%;
    box-shadow: -6px -2px 11px 0 #15172533;
    display: flex;
    flex-direction: column;
}

.messageTopBlock .messageTitle {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
}
.mapTitle {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.messageBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #E7EAEF;
    padding: 10px 20px;
}

.messageIcon {

    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2c2c2c;
}

.messageIcon {
    color: #f03737;
}

.messageIcon .messageDate {
    font-size: 13px;
    margin: 0 0 0 20px;
    color: #2c2c2c;
}
.messageTxtOne {
    font-weight: bold;
    font-size: 13px;
}
.messageText {
    display: flex;
    flex-direction: column;
    font-size: 13px;

}


.messageText .messageTxt {
    margin-top: 10px;
    text-align: start;
    font-size: 13px;
}

.infoTitleTopBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
   }

.map {
    width: 65%;
    height: 90%;
    box-shadow: -6px -2px 11px 0 #15172533;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.titleBase {
    font-size: 14px;
    padding-top: 20px;
}

.info {
    width: 95%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.infoItem, .infoItemOne {
    width: 20%;
    box-shadow: -6px -2px 11px 0 #15172533;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.infoBlockTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    height: 50px;
    border-bottom: 1px solid #E7EAEF;
}

.blockStation {
    width: 85%;
    display: flex;
    justify-content: space-evenly;
}
.blockStationOne {
    width: 85%;
    display: flex;
    justify-content: center;
}
.networkStation {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
}
.networkStationOne {
    display: flex;
   width: 50%;
    align-items: center;
    justify-content: space-between;
    
}
.infoTitle {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0;
}
.infoTitle:last-child {
    margin-left: 10px;
}
.iconStationHot {
    transform: rotate(-90deg);
}

.iconStation, .iconStationHot {
    width: 40px;
    height: 20px;
    color: #205A79;
  
}
.slash {
    color: #2c2c2c;
    font-size: 30px;
    font-weight: bold;
}
.networkStation .infoText {
     font-size: 12px;
    margin-bottom: 4px;

}

.batteryPicDash {
    width: 85%;
    margin-bottom: 10px;
}

.batteryLineDash {
    height: 5px;
    border-radius: 5px;
    border: none;
    background-color: #205A79;
}

.numberStation {
    font-size: 30px;
    color: #205A79;
}

.numberStationOffline {
    color: #f03737;
    font-size:30px;   
}

@media (max-width: 1440px) {
    .networkStation {
        font-size: 28px;
    }
    .bottomConteiner {
        margin: 45px auto 0;
    }


}

@media (max-width: 1220px) {
    .numberStation,  .numberStationOffline {
        font-size: 22px;
     
    }
    
   

}


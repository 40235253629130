.mainPage {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.conteiner {
    width: 100%;
    height: 93%;
    display: flex;
    position: relative;
}

.conteinerPreloder {
    position: absolute;
    margin: 0 auto;
}

.pages {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.pagesLoading {
    width: 100%;
    height: 93vh;
    overflow: auto;
    opacity: 0.3;
    z-index: -1;
    position: relative;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7%;
    background-color: #2c2c2c;
    position: relative;
}

.logoBlock {
    display: flex;
}

.logo {
    height: 40px;
    margin-left: 12px;
}

.minilogo {
    width: 40px;
    margin: 6px 10px 0 6px
}

.filterBtn {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.filterIcon {
    width: 25px;
    height: 25px;
}

.searchBlock {
    display: flex;
    align-items: center;
}

.search {
    width: 200px;
    height: 30px;
    background-color: #fff;
    border: none;
    animation: show 1s forwards 1;
}

.searchHidden {
    width: 0px;
    height: 30px;
    background-color: #2c2c2c;
    border: none;
    animation: hidden 1s forwards 1;
}

.search::placeholder {
    font-size: 13px;
}

.lupa {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 1px solid #636466;
    cursor: pointer;
    margin-right: 60px;
}

.lupaIcon {

    height: 25px;
    width: 25px;
    color: #fff;


}

@keyframes show {
    0% {
        width: 0px;
    }

    100% {
        width: 200px;
    }
}

@keyframes hidden {
    0% {
        width: 200px;
        background-color: #fff;
    }

    90% {}

    100% {
        background-color: #2c2c2c;
        width: 0px;
    }
}
.streets {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.list {
    height: 37px;
    display: flex;
    flex-direction: column;  
    font-size: 13px;
    border-bottom: 1px solid #E7EAEF;  
    animation: doc2 0.5s linear 1 backwards;
    cursor: pointer;
}
.list:hover {
    background-color: #E7EAEF;
    animation: doc 1s linear 1 backwards;
}
.listStreets {
    display: flex;
    padding: 10px 50px 10px 50px;
}

.itemStreet {
    font-size: 14px;
  
}


@keyframes doc {
    from {
        background-color: white;
        color: #2c2c2c;
        }

    to {
        background-color: #E7EAEF;
        color: 2c2c2c;
    }

}

@keyframes doc2 {
    from {
        background-color: #E7EAEF;
        color: 2c2c2c;
    }

    to {
        background-color: FFF;
        color: #2c2c2c;

    }
}


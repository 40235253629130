.panelRight {
    position: fixed;
    width: 550px;
    height: 800px;
    background-color: white;
    z-index: 1;
    box-shadow: -6px -2px 11px 0 #15172533;
    top: 60px;
    right: -500px;
    animation: show 1s forwards 1;
    overflow-y: scroll;
}

.panelRightHidden {
    position: fixed;
    width: 500px;
    background-color: white;
    z-index: 1;
    box-shadow: -6px -2px 11px 0 #15172533;
    top: 60px;
    right: 0px;
    animation: hidden 1s forwards 1;
}

.sectionTop {
    display: flex
}

.header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.headerLeft {
    display: flex;
}

.iconLoc, .iconClose {
    width: 30px;
    height: 20px;
}

.iconLoc {
    color: #636466;
    margin-right: 5px;
}

.iconClose {
    cursor: pointer;
    color: #2c2c2c;
}

.titleAddress {
    font-size: 13px;
    font-weight: bold;
}

.btn {
    width: 100%;
    height: 80px;
    background-color: #E7EAEF;
    display: flex;
    cursor: pointer;
}

.btnIcon {
    width: 17px;
    height: 17px;
}

.btnInfo {
    width: calc(100%/3);
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
}

.btnInfoActive {
    width: calc(100%/3);
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 2px solid #2E5775;
    cursor: pointer;
    color: #2E5775;
}

.btn .txtBtn {
    margin: 0;
}

.sectionInfo {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sectionPic {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sctionDesc {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.desc {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    border-bottom: 1px solid #E7EAEF;
}

.desc p:last-child {
    font-weight: bold;
}

.battery {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.batteryText {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.batteryTxt {
    font-size: 14px;
    font-weight: bold;
}

.batteryNum {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.batteryPic {
    width: 100%;
    height: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    border: #E7EAEF solid 1px;

}

.batteryLine {

    height: 100%;
    border-radius: 5px;
    border: none;
}

/*Message*/
.message {
    padding: 0 20px;
}

.messageBlock {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #E7EAEF;
    padding: 20px 0 20px 0;
}

.messageIcon {
    width: 60px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f03737;
    margin-top: 7px;
}

.messageText {
    display: flex;
    flex-direction: column;
}

.messageText .messageTxt, .messageDate {
    margin-top: 10px;
    text-align: start;
    margin-left: 30px;
    font-size: 13px;
}
.archive{
    display: flex;
    justify-content: space-around;
}

@keyframes show {
    0% {
        right: -500px;
    }

    100% {
        right: 0px;
    }
}

@keyframes hidden {
    0% {
        right: 0px;
    }

    100% {
        right: -500px;
    }
}
.charts {
    width: 90%;
    height: 100%;
    overflow: hidden;
    margin-top: 30px;
    margin-left: 30px;
}

.chartsTitle {
    text-align: center;
    font-size: 14px;
    font-weight: bold;

}
.chart {
    
    width: 80%;
    user-select: none;
    pointer-events: none;
    touch-action: none;
    position: relative;
}

.chartsTopBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-bottom: 1px solid #E7EAEF;
}
.houses {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.housesList {
   
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E7EAEF;
    animation: doc2 0.5s linear 1 backwards;
    cursor: pointer;
    justify-content: space-between;
}

.housesList:hover {
    background-color: #E7EAEF;
    animation: doc 1s linear 1 backwards;
}


@keyframes doc {
    from {
        background-color: white;
        color: #2c2c2c;
    }

    to {
        background-color: #E7EAEF;
        color: 2c2c2c;
    }

}

@keyframes doc2 {
    from {
        background-color: #E7EAEF;
        color: 2c2c2c;
    }

    to {
        background-color: FFF;
        color: #2c2c2c;

    }
}

.blockHousesList {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px 10px 50px;
}

.houseEl {
    display: flex;
    font-size: 14px;
    border: 1px solid transparent;
}

.itemsHouseEl {
    display: flex;
    padding: 10px 0 10px 75px;
    font-size: 14px;
}
body {
  margin: 0;

  font-family: 'Proxima Nova Lt', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {

}



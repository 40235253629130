.blockPreloder {
 
   position: absolute;
   top: 45%;
   left: 45%;
}

.pic {
    width: 60px;
    height: 60px;
    rotate: 0deg;
    transition: all 0.5s 0s;
 }

.login {
    width: 100%;
    height: 100vh;
    background-image: url('../../img/start.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.formlog {
    display: flex;
    flex-direction: column;
    width: 320px;
    background-color:rgba(18, 61, 68, 0.65);
    position: absolute;
    left: 43%;
    top: 30%;
    transition: right 1s;
    font-family: 'Original Garamond';
    font-style: normal;
    z-index: 20;
  }

.logoBlock {
    display: flex;
    align-items: center;
    justify-content: center;
     width: 100%;
    height: 50px;

}

.logo {
    width: 160px;
    height: 35px;
 }

  .formlog input {
    font-family: 'Proxima Nova Lt', sans-serif;
    width: 100%;
    height: 35px;
    font-size: 14px;
    margin-top: 5px;
    color: #2c2c2c;
}

  .hello {
    font-size: 18px;
    margin-bottom: 20px;
 }

 .loginBtn {
    color: #fff;
    font-size: 14px;
    width: 108%;
    height: 35px;
    background-color: #FD3737;
    border: none;
    margin-top: 30px;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.formlog input {
    font-family: 'Proxima Nova Lt', sans-serif;
    width: 100%;
    height: 35px;
    font-size: 14px;
    margin-top: 10px;
    padding-left: 10px;
    color: #2c2c2c;
}

.close {
   
    font-size: 32px;
    position: absolute;
    right: -35px;
    opacity: 0;

}

.blockTop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
}

.inputLog {
    height: auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px 50px 50px;
}

::placeholder {
    font-size: 12px;
    
}

.demoBtn {
    color: #fff;
    font-size: 14px;
    width: 40%;
    height: 35px;
    background-color: #2E5775; 
    border: none;
    margin-bottom: 30px;
    font-family: 'Proxima Nova Lt', sans-serif;
    margin-left: 98px;
}

@media (max-width: 1440px) {
    .formlog {
        left: 40%;
    }
    
}

@media (max-width: 1280px) {
    .formlog {
        left: 38%;
    }
    
}

@media (max-width: 1024px) {
    .formlog {
        left: 35%;
    }
    
}

@media (max-width: 768px) {
    .formlog {
        left: 30%;
    }
    
}
.archive {
    margin-top: 20px;
}
.archiveBlock {
    display: flex;
    justify-content: space-between;
    height: 30px;
    font-size: 13px;
    border-bottom: 1px solid #E7EAEF;
    margin: 10px 50px;
    padding: 0px 40px;
}

.itemsReportsDate {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    
}
.itemsReportsDate:last-child {
margin-top: 10px;
}
.item{
    font-size: 14px;
   margin-top: 10px;
}
.startDate,
.endDate {
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.btnExport {
display: flex;
justify-content: flex-end;
}
.buttonExport {
    width: 340px;
    height: 30px;
    background-color: #2E5775;
 color: #fff;
    margin-right: 15px;
    font-family: 'Proxima Nova Lt', sans-serif;
   cursor: pointer;
}
.address {
    height: 88vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.citySelector {
    width: 100%;
    height: 50px;
}
h2 {
    font-size: 16px;
    text-align: start;
    text-transform: uppercase;
    color: #2c2c2c;
    margin-left: 50px;
}

.header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background: #E7EAEF;
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: bold;
    color: #2c2c2c;
}


.conteiner {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
   
}

.tableCity, .streetTable,
.houseTable, .flatsTable {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.tableCity::-webkit-scrollbar,
.streetTable::-webkit-scrollbar,
.houseTable::-webkit-scrollbar,
.flatsTable::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.areas {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E7EAEF;
}

.wrapperAreas {
    animation: doc2 0.5s linear 1 backwards;
    height: 37px;
    border-bottom: 1px solid transparent;
}

.iconAddress {
    cursor: pointer;
    width: 14px;
}

.wrapperAreas:hover {
    background-color: #E7EAEF;
    animation: doc 1s linear 1 backwards;
}

@keyframes doc {
    from {
        background-color: white;
        color: #2c2c2c;

    }

    to {
        background-color: #E7EAEF;
        color: 2c2c2c;
    }

}

@keyframes doc2 {
    from {
        background-color: #E7EAEF;
        color: 2c2c2c;
    }

    to {
        background-color: FFF;
        color: #2c2c2c;

    }
}

.wrapper {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 10px 50px;
}

.region {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.listRegion {
    margin-right: 50px;
}

.cityOfAreas {
    font-size: 14px;
    padding: 10px 0 10px 75px;
    border-bottom: 1px solid #E7EAEF;
    animation: doc2 0.5s linear 1 backwards;
    cursor: pointer;
}

.cityOfAreas:hover {
    background-color: #E7EAEF;
    animation: doc 1s linear 1 backwards;
}

.cityOfAreas:last-child {
    border-bottom: none;
}

.itemsCityOfAreas {
    width: 100%;
    display: flex;
    flex-direction: column;
}


@media (max-width: 1220px) {
    .houseTable, .flatsTable {
    width: 21%;
     
    }
    
    .tableCity, .streetTable
     {
        width: 29%;
        
    }
    

}

.blockchoiceReportsBottom {
    margin-top: 20px;
}

.itemsReports {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.titleHeaderRow {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background: #E7EAEF;
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    font-weight: bold;
    color: #2c2c2c;


}

.headerReports {
    width: 100%;
    display: flex;
    justify-content: space-around;

}


.column {
    width: 12%;
    cursor: grab;
    
}

.headerRow {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding-left: 15px;
    border-bottom: 1px solid #E7EAEF;
    height: 35px;
    margin: 0;

}
.conteiner {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #2c2c2c;
    position: relative;
}

.logoBlock {
    display: flex;
}

.logo {
    height: 42px;
    margin-left: 12px;
}
.errorPage {
    height: 100%;
    background-image: url('../../src/img/start.jpeg');
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.errorBox {
    width: 80%;
    background: rgba(34, 90, 103, 0.42);
    margin-top: 100px;
}
.errorNumber {
    display: flex;
  position: absolute;
width: 1168px;
height: 686px;
left: 370px;
top: 214px;
}

.titleMaintenanceMode {
    font-size: 100px;
    font-family: 'Original Garamond';
    color: #fff;
  
}
.textBlock {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

}

.errorText {
    width: 80%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: #fff;
    padding: 20px 0;
}

.desc {
    font-size: 18px;
    font-family: 'Proxima Nova Lt', sans-serif;
    color: #fff;
}

.contacts {
    color: #fff;
    padding: 20px 0 100px;
}


.conteiner {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #2c2c2c;
    position: relative;
}

.logoBlock {
    display: flex;
}

.logo {
    height: 42px;
    margin-left: 12px;
}
.comingSoonPage {
    height: 100%;
    background-image: url('../../src/img/start.jpeg');
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;


}

.comingSoonBox {
    width: 70%;
    height: 700px;
    background: rgba(34, 90, 103, 0.42);
    margin-top: 100px;
}



.titleComingSoon {
    width: 1000px;
    font-size: 100px;
    font-family: 'Original Garamond';
    color: #fff;
    padding: 50px 0;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    margin: 30px 0;
}

.textBlock {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

}

.comingSoonTextTop {
    width: 800px;
    display: flex;
    flex-direction: column;
    color: #fff;
}

.comingSoonTextBottom {
    margin-bottom: 50px;
}


.desc {
    font-size: 18px;
    font-family: 'Proxima Nova Lt', sans-serif;
    color: #fff;
}

.comingSoonDate {
  
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
    color: #fd3737;
}
.comingSoonNumber {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
}

.timeBlock {
    margin-top: -220px;
}
.headerTytle {
    position: relative;
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 50px;
}

.iconLupa {
    width: 30px;
    height: 20px;
    margin-right: 48px;
    cursor: pointer;
}

.search {
    width: 200px;
    height: 30px;
    background-color: #fff;
    border: none;
    animation: show 1s forwards 1;
}

.searchHidden {
    width: 0px;
    height: 30px;
    background-color: #2c2c2c;
    border: none;
    animation: hidden 1s forwards 1;
    position: absolute;
    right: 0;
}

.search::placeholder {
    font-size: 13px;
}

@keyframes show {
    0% {
        width: 0px;
    }

    100% {
        width: 200px;
    }
}

@keyframes hidden {
    0% {
        width: 200px;
        background-color: #fff;
    }


    100% {
        background-color: #2c2c2c;
        width: 0px;
    }
}

.searchBlock {
    display: flex;
    align-items: center;
}

.search {
    width: 200px;
    height: 30px;
    background-color: #fff;
    border: none;
    animation: show2 1s forwards 1;
}

.searchHidden {
    width: 0px;
    height: 30px;
    background-color: #2c2c2c;
    border: none;
    animation: hidden2 1s forwards 1;
}

.search::placeholder {
    font-size: 13px;
}

.lupa {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #636466;
    cursor: pointer;
    margin-right: 60px;
}

.lupaIcon {

    height: 25px;
    width: 25px;
    color: #fff;


}

@keyframes show2 {
    0% {
        width: 0px;
    }

    100% {
        width: 200px;
    }
}

@keyframes hidden2 {
    0% {
        width: 200px;
        background-color: #fff;
    }

    90% {}

    100% {
        background-color: #2c2c2c;
        width: 0px;
    }
}

.addressFilter .headerTytle {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 0 0;
}

.addressFilter .iconLupa {
    width: 30px;
    height: 20px;
    margin-right: 10px;
}

@media (max-width: 1220px) {
    .numberStation,  .numberStationOffline {
        font-size: 22px;
     
    }
    
    .headerTytle:nth-child(n+3):nth-child(-n+4) {
       
        width: 20%;
    
    }
    .headerTytle:nth-child(n+1):nth-child(-n+2) {
       
        width: 30%;
    
    }


}
.pages {
    height: 100%;
    position: relative;
}

.title {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title h2 {
    font-size: 16px;
    text-align: start;
    text-transform: uppercase;
    color: #2c2c2c;
    margin-left: 50px;
}

.titleFilter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin-right: 45px;
    border-right: 1px solid #2c2c2c;
}

.table {
    max-height: 100vh;
}

.headerT {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #E7EAEF;
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.25);
    column-gap: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #2c2c2c;
}

.itemT {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 35px;
    font-size: 13px;
    border-bottom: 1px solid #E7EAEF;
    animation: doc2 0.5s linear 1 backwards;
    column-gap: 20px;
    cursor: pointer;
}

.itemT:hover {
    background-color: #E7EAEF;
    animation: doc 1s linear 1 backwards;
}

.promptWrapper {
    position: relative;
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prompt {
    position: absolute;
    width: 0px;
    height: 0px;
    display: none;
    left: 50px;
}
.headerT .t0 {
    margin-left: 12px;
}
.t0 {
    width: 100px;
    text-align: center;
}

.t00 {
    width: 100px;
    align-items: center;
}

.t00:hover~.prompt {
    position: absolute;
    right: -20px;
    width: 70px;
    height: 35px;
    border-radius: 5px;
    background-color: #79797a;
    border: none;
    top: -6px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.promptText {
    text-align: center;
    font-size: 11px;
 
}
.promptTextT {
    
    text-align: center;
    font-size: 11px;
}


.t1 {
    width: 200px;
    text-align: center;
}

.t2 {
    width: 300px;
    text-align: center;
}
.headerT .t3 {
    text-align: center;
}
.t3 {
    width: 550px;
    text-align: left;
}


@keyframes doc {
    from {
        background-color: white;
        color: #2c2c2c;

    }

    to {
        background-color: #E7EAEF;
        color: 2c2c2c;
    }

}

@keyframes doc2 {
    from {
        background-color: #E7EAEF;
        color: 2c2c2c;
    }

    to {
        background-color: FFF;
        color: #2c2c2c;

    }
}

.pagesTotal {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    height: 50px;
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.25);

}

.pagesLeft {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: none;

}

.blockRight, .blockLeft {
    width: 45%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.blockRight {
    justify-content: flex-end;
}

.pagesRight {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.pagesAmount {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 5%;
    ;
}

.choiceNumber {
    width: 50px;
    border: 1px solid #E7EAEF;
    margin-left: 5%;
    text-align: center;
}

.pageTotal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 55%;
    margin-left: 7%;
}

.textTotal {
    margin-left: 5%;
}

.pageTotalBtn {
    margin-left: 5%;
    background-color: #fff;
    border: 1px solid #E7EAEF;
    width: 70px;
    cursor: pointer;
}

.pageL {

    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}

.pagesTotal .page, .page1, .pageTxt, .pagePart {
    width: 25px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 0;
}

.pageTxt {
    width: auto;
}

.pageBack, .pageForward {

    cursor: pointer;
}

.iconLeft, .iconRight, .iconDovn {
    width: 13px;
    cursor: pointer;
    padding: 0 5px;
}

.pagePart {
    width: 50px;
    cursor: pointer;
    border: 1px solid #E7EAEF;
}


@media (max-width: 1220px) {
    .titleFilter {
        margin-right: 60px;
    }
    .headerT {
        font-size: 13px;
    }
   .headerT .t0 {
    margin-left: 12px;
}
.blockRight {
    width: 50%;
}

}

.mapTopBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-bottom: 1px solid #E7EAEF;

}
.mapTitle {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.map {
    width: 95%;
    box-shadow: -6px -2px 11px 0 #15172533;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mapBottomBlock {
    width: 95%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    overflow: hidden;
}
.mapBlockRight {
    overflow: hidden;
}
.mapBlockLeft {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin-top: 25px;

}
.iconMapStation,
.iconMapStationOffline {
    width: 40px;
    height: 30px;
    color: #205A79;
}

.stationOnline,
.stationOffline {
    display: flex;
   align-items: flex-end;
   margin-bottom: 20px;
   font-size: 13px;
}
.iconMapStationOffline {
    color: #f03737;
}

.offlineTxt,
.onlineTxt {
    margin-left: 10px;
}




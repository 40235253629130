.filter {
    position: fixed;
    width: 400px;
    background-color: #2c2c2c;
    color: #fff;
    z-index: 1;
    box-shadow: -6px -2px 11px 0 rgba(21, 23, 37, .2);
    top: 60px;
    right: 0px;
    animation: show 1s forwards 1;
}
.filterHidden{
    position: fixed;
    width: 500px;
    background-color: #2c2c2c;
    color: #fff;
    z-index: 1;
    box-shadow: -6px -2px 11px 0 rgba(21, 23, 37, .2);
    top: 60px;
    right: 0px;
    animation: hidden 1s forwards 1;
}

.iconClose {
    cursor: pointer;
    
}

.filter button {
    height: 30px;
    margin-bottom: 30px;
}
.header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #636466;

}

.filterTitle {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 20px;
}
.filterHeader {
    color: #636466;
    width: 25px;
    height: 25px;
}

.filterCatalog {
    padding: 0 20px;
    display: flex;
    flex-direction: column;

}

.filterStatus, .filterSubject, .filterResurs {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #636466;
}

.radio {
    accent-color: #2E5775;
    border: none;
    

}

.statusText {
    display: flex;
    margin-top: 20px;
    cursor: pointer;
}

.statusText .statusTxt {
    margin-top: 0;

}

.filterIcon {
    margin-left: 10px;
}

.statusItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0 30px 160px;
}

.filterTxt {
    font-size: 14px;
    margin-left: 10px;
}
.statusBlock {
    margin-top: 10px;
}

.filterButton {
    margin-top: 30px;
    background-color: #2E5775;
    color: #fff;
    border: none;
    width: 200px;
    margin-left: 80px;
}
@keyframes show {
    0% {
        right: -500px;
    }

    100% {
        right: 0px;
    }
}

@keyframes hidden {
    0% {
        right: 0px;
    }

    100% {
        right: -500px;
    }
}
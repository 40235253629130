.mainPage {
    width: 100%;
    height: 100vh;

}

.conteiner {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #2c2c2c;
    position: relative;
}

.logoBlock {
    display: flex;
}

.logo {
    height: 42px;
    margin-left: 12px;
}

.errorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    width: 100%;
}

.error404Block {
    display: flex;
  
}
.textError {
    font-size: 265px;
    margin: 70px 0 30px;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.pic {

    margin-top: 60px;
    padding: 0 10px;

}


.errorNumber {
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorText {
    width: 55%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #2c2c2c;
    border-bottom: 1px solid #2c2c2c;
    margin-bottom: 50px;
    margin-top: 50px;
    padding: 30px 0;
}

.desc {
    font-size: 18px;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.btnExit {
    width: 250px;
    height: 40px;
    font-size: 20px;
    background-color: #205A79;
    color: #fff;
    border: none;
    font-family: 'Proxima Nova Lt', sans-serif;
}
.reports {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

 h2 {
    font-size: 16px;
    text-align: start;
    text-transform: uppercase;
    color: #2c2c2c;
    margin-left: 50px;

}

.blockChoiceReportsTop {
    display: flex;
    width: 100%;
    margin: 0 auto;
background-color: #E7EAEF;
box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.25);
  
}

.choiceReportsLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 10px 10px 10px 50px;

}

.choiceReportsRight {
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}
.choiceReports {
    display: flex;
}

.titleReports {
    display: flex;
 
}

.title {
    font-size: 14px;
    margin: 10px 0;
    font-weight: bold;
}

.itemsReports {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.itemsReports h2 {
    text-align: center;
}

.checkReports {
    display: flex;
 }

.item{
    font-size: 14px;
    margin: 10px 0 10px 10px;
}

.radio {
    accent-color: #2E5775;
    border: none;
    margin: 10px 0;
}

.searchSity, 
.searchAktiv {
    margin: 5px 0 0 -50px;
}
.wrapperShow {
    margin-top: 20px;
    height: 750px;
    overflow: auto;
}
.wrapperShow::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.wrShh4 {
    cursor: pointer;
}
/*.wrShh4:hover{
    background-color: #fff;
    color: #2c2c2c;
}*/

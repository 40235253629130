.navBar {
    display: flex;
    height: 100%;
    background-color: #2c2c2c;

}

.navBarMini {
    width: 60px;
    height: 100%;
    background-color: #2c2c2c;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navBarShow {
    width: 160px;
    height: 100%;
    background-color: #2c2c2c;
    animation: show forwards 0.6s 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.navBarShow p:hover {
    color: #2E5775;
}

.navBarHidden {
    width: 0px;
    height: 100%;
    background-color: #2c2c2c;
    animation: hidden forwards 0.6s 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navBarHidden p {
    opacity: 0;
}

.logoBar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 50px;
}



.iconsBlock {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.aktiv {
    width: 5px;
    height: 50px;

}

.icon {
    width: 23px;
    height: 30px;
    color: #fff;
    margin-left: 10px;
}

.icon2 {
    width: 23px;
    height: 30px;
    color: #fff;
    margin-left: 10px;
    opacity: 0.1;
}

.iconItem {
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #636466;
    cursor: pointer;
}

.icon:hover {
    color: #2E5775;
    cursor: pointer;
}

.iconTitle {
    color: #fff;
    margin-left: 15px;

}

.iconTitleExit {
    margin-left: 10px;
    margin-top: 0;
}

.iconBottom {
    display: flex;
    flex-direction: column;

    margin-left: 5px;
    cursor: pointer;
}

.iconBottomMini {
    display: flex;
    flex-direction: column;

    margin-left: 5px;
    cursor: pointer;
}


.iconEx {
    rotate: 90deg;
    width: 20px;
    height: 50px;
    color: #fff;
    margin-left: 10px;

}

.iconEx:hover {
    color: #2E5775;
    cursor: pointer;
}


.iconName,
.iconExit {
    display: flex;
    align-items: center;
}

.iconExit {
    font-size: 14px;
    margin-left: 5px;
    margin-top: 20px;
    color: #fff;
}

@keyframes hidden {
    0% {
        width: 160px;
    }

    100% {
        width: 0px;
    }
}

@keyframes show {
    0% {
        width: 0px;
    }

    100% {
        width: 160px;
    }
}
.addressFilter{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.choiceReports {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    margin: 10px 0;
    font-weight: bold;
}
.choiceReportsAdress {
    position: relative;
    display: flex;
    width: 350px;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 10px;
}
.choiceReportsAdressActive {
    position: relative;
    margin-top: -20px;
    display: flex;
    width: 350px;
    height: 615px;
    padding: 0 15px;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #E7EAEF;
    background-color: #2c2c2c;
    color: #fff;
    z-index: 1;
    overflow: hidden;
}
.title {
    font-size: 14px;
    font-weight: normal;
}

.choiceReportsTitle {
    width: 22%;
}
.iconItems {
    transform: rotate(90deg);
    cursor: pointer;
    position: absolute;
    right: 15px;
   
}
.choiceReportsAdressActive .iconItems {
    transform: rotate(90deg);
    cursor: pointer;
margin-top: 22px;
   
}
h4 {
    font-size: 14px;
    font-weight: normal;
}

